exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-journal-jsx": () => import("./../../../src/pages/journal.jsx" /* webpackChunkName: "component---src-pages-journal-jsx" */),
  "component---src-pages-locations-jsx": () => import("./../../../src/pages/locations.jsx" /* webpackChunkName: "component---src-pages-locations-jsx" */),
  "component---src-pages-newsletter-jsx": () => import("./../../../src/pages/newsletter.jsx" /* webpackChunkName: "component---src-pages-newsletter-jsx" */),
  "component---src-pages-shop-jsx": () => import("./../../../src/pages/shop.jsx" /* webpackChunkName: "component---src-pages-shop-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/Event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-journal-post-jsx": () => import("./../../../src/templates/JournalPost.jsx" /* webpackChunkName: "component---src-templates-journal-post-jsx" */),
  "component---src-templates-location-jsx": () => import("./../../../src/templates/Location.jsx" /* webpackChunkName: "component---src-templates-location-jsx" */),
  "component---src-templates-location-space-jsx": () => import("./../../../src/templates/LocationSpace.jsx" /* webpackChunkName: "component---src-templates-location-space-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/Page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */)
}

