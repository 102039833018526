// React
import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"

// Material UI
import { Box } from "@mui/material"
import useScrollTrigger from "@mui/material/useScrollTrigger"

// Constants
import * as routes from "../../../src/constants/routes"

// Logos
import LogoIcon from "../../assets/logo-icon.png"
import LogoWord from "../../assets/logo-word.png"

// Framer Motion
import { motion, useAnimation } from "framer-motion"

export default function NavLogo({ sm }) {
  const handleClickLogo = () => navigate(routes.home)

  const controls = useAnimation()
  const controlsIcon = useAnimation()

  const [animationLarge, setAnimationLarge] = useState(true) // Check if animation has finished

  const sequence = async () => {
    // await controls.start({
    //   maxHeight: `108px`,
    //   display: `flex`,
    //   transform: `rotateY(0deg)`,
    //   transition: { duration: 0 },
    // })
    // Hide Words
    await controls.start({
      // maxHeight: `75px`,
      // transform: `rotateY(90deg)`,
      opacity: 0,
      transition: {
        ease: [0.37, 0.07, 0.58, 0.85],
        duration: 0.5,
        // maxHeight: { duration: 0.5 },
        // transform: { duration: 1 },
      },
    })
    await controls.start({
      maxHeight: `75px`,
      transition: { duration: 0.15 },
      transitionEnd: {
        display: "none",
        opacity: 0,
        transform: `rotateY(0deg)`,
      },
    })
    // Show Icon
    await controlsIcon.start({
      display: "flex",
      transform: `rotateY(0deg)`,
      transition: { duration: 0.5, ease: [0.37, 0.07, 0.58, 0.85] },
    })
    setAnimationLarge(false) // Mark animation finished
  }

  const sequenceReturn = async () => {
    // Hide Icon
    await controlsIcon.start({
      opacity: 0,
      transition: { duration: 0.5 },
      transitionEnd: {
        display: "none",
        transform: `rotateY(-90deg)`,
        opacity: 1,
      },
    })
    await controlsIcon.start({
      display: "none",
    })
    // Show Words
    await controls.start({
      display: `flex`,
      maxHeight: sm ? `75px` : `108px`,
      transition: { duration: 0.3 },
    })
    await controls.start({
      opacity: 1,
      transition: { duration: 0.5 },
    })
    setAnimationLarge(true) // Mark return animation finished
  }

  const trigger = useScrollTrigger({ disableHysteresis: true })

  useEffect(() => {
    if (trigger && animationLarge) {
      sequence()
    } else if (!trigger && !animationLarge) {
      sequenceReturn()
    }
  }, [trigger])

  return (
    <Box
      onClick={() => handleClickLogo()}
      sx={{
        cursor: "pointer",
        marginLeft: { xs: 0, sm: 0, md: 6, lg: 6, xl: 6 },
        marginRight: { xs: 0, sm: 0, md: 6, lg: 6, xl: 6 },
        width: sm ? `100%` : `200px`,
        gridColumn: sm ? `2` : ``,
        display: `flex`,
        justifyContent: `center`,
        "& img": {
          width: `auto`,
          // maxWidth: `200px`,
          maxHeight: {
            xs: `75px`,
            sm: `75px`,
            md: `108px`,
            lg: `108px`,
            xl: `108px`,
          },
          paddingTop: 2,
          paddingBottom: 1,
        },
      }}
    >
      <motion.img
        src={LogoWord}
        alt="Bouncespace logo text"
        animate={controls}
      />
      <motion.img
        src={LogoIcon}
        alt="Bouncespace logo icon"
        animate={controlsIcon}
        style={{ height: "75px", width: `auto` }}
        initial={{ display: "none", transform: `rotateY(-90deg)` }}
      />
    </Box>
  )
}
