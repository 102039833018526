// React
import React from "react"

// Components
import { Link as GatsbyLink, graphql, useStaticQuery } from "gatsby"
import { Link as MUILink, Typography } from "@mui/material"
// import { GatsbyImage } from "gatsby-plugin-image"

// Material ui
import { Container, Divider, Grid, Box, Link } from "@mui/material"

// Constants
import * as routes from "../../constants/routes"
import EmailOnlyForm from "../NewsletterForms/EmailOnlyForm"

// Logo
// import LogoIcon from "../../assets/logo-icon.png"

const largeStyles = {
  marginTop: "auto",
  marginBottom: -0.5,
  color: "white",
  fontWeight: "bold",
  fontSize: 36,
  "&:hover": {
    color: "#DAA14C",
  },
}

const smallStyles = {
  marginTop: "auto",
  marginBottom: 1,
  marginRight: 1,
  color: "#263847",
  fontSize: 16,
  textDecoration: "none",
  "&:hover": {
    textDecoration: "underline",
  },
}

function FooterLink(props) {
  const { text, href, isExternal, topMargin, large } = props
  const linkStyle = large ? largeStyles : smallStyles
  return isExternal ? (
    <MUILink
      to={href}
      sx={linkStyle}
      target="_blank"
      rel="noreferrer"
      component={GatsbyLink}
      underline="none"
      style={{ marginTop: topMargin }}
    >
      {text}
    </MUILink>
  ) : (
    <Link
      to={href}
      sx={linkStyle}
      component={GatsbyLink}
      underline="none"
      style={{ marginTop: topMargin }}
    >
      <Typography variant={large ? "windsor" : ""}>{text}</Typography>
    </Link>
  )
}

const linksBoxStyles = {
  display: "flex",
  marginBottom: 2,
  flexDirection: "column",
}

function useFooterAssets() {
  return useStaticQuery(
    graphql`
      query {
        logo: contentfulAsset(contentful_id: { eq: "54YqATRb0l0kJt7y3b73ow" }) {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
          file {
            url
          }
        }
      }
    `
  )
}

export default function Footer(props) {
  const { logo } = useFooterAssets()

  return (
    <Box
      sx={{
        width: "100%",
        display: "inline-block",
        backgroundColor: `primary.main`,
      }}
    >
      <Divider />
      <Container
        sx={{
          paddingLeft: { xs: 3, sm: 3, md: 4, lg: 4, xl: 4 },
          paddingRight: { xs: 3, sm: 3, md: 4, lg: 4, xl: 4 },
          paddingTop: { xs: 3, sm: 3, md: 5, lg: 5, xl: 5 },
          paddingBottom: { xs: 3, sm: 3, md: 5, lg: 5, xl: 5 },
        }}
      >
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={4}>
            <Box
              sx={{
                display: "flex",
                marginBottom: 2,
                flexDirection: "column",
                marginTop:
                  -1 /* To fix alignment, more elegant solution possible */,
              }}
            >
              <FooterLink text="LOCATIONS" href={routes.locations} large />
              <FooterLink text="JOURNAL" href={routes.journal} large />
              <FooterLink text="EVENTS" href={routes.events} large />
              <FooterLink text="SHOP" href={routes.shop} large />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: {
                md: "center",
                xs: "flex-start",
              },
            }}
          >
            <Box sx={linksBoxStyles}>
              <FooterLink
                text="CONTACT"
                href={routes.contact}
                isExternal={false}
              />
              <FooterLink
                text="OUR STORY"
                href={routes.story}
                isExternal={false}
              />
              <FooterLink
                text="JOIN OUR TEAM"
                href={routes.team}
                isExternal={false}
              />
              <FooterLink
                text="INSTAGRAM"
                href={routes.instagram}
                isExternal={true}
                // topMargin={`16px`} // Optional top margin if these need to be separated from other menu items
              />
              <FooterLink
                text="YOUTUBE"
                href={routes.youtube}
                isExternal={true}
              />
              <FooterLink
                text="LINKEDIN"
                href={routes.linkedin}
                isExternal={true}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            sx={{
              display: "flex",
              justifyContent: { xs: "flex-start", md: "flex-end" },
              alignSelf: "flex-start",
            }}
          >
            <EmailOnlyForm />
          </Grid>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 2,
            width: "100%",
            "& img": {
              width: 70,
              paddingTop: 3,
              paddingBottom: 1,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              "& a": {
                marginRight: 2,
              },
            }}
          >
            <FooterLink
              text="Terms & Conditions"
              href={routes.termsOfService}
            />
            <FooterLink text="Privacy Policy" href={routes.privacy} />
            <FooterLink text="Cookie Policy" href={routes.cookies} />
          </Box>
          <Link to={routes.home} component={GatsbyLink}>
            <img src={logo.file.url} alt="Bouncespace logo icon white" />
          </Link>
        </Box>
      </Container>
    </Box>
  )
}
