// React
import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

// Material UI
import {
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import { Clear } from "@mui/icons-material"

// Constants
import * as routes from "../../../src/constants/routes"

export default function DrawerMenu(props) {
  const { isOpen, toggleDrawer, locations, handleNavigateClick } = props

  return (
    <Drawer
      id="drawer-menu"
      anchor="right"
      open={isOpen}
      onClose={toggleDrawer}
      sx={{
        "& .MuiDrawer-paper": {
          backgroundColor: "background.default",
        },
      }}
    >
      <List
        sx={{
          paddingTop: 0,
          width: 250,
          maxWidth: "100%",
          "& .MuiListItemText-root": {
            color: "titles.main",
          },
          "& .MuiListItem-root": {
            "&:hover": {
              "& .MuiListItemText-root": {
                color: "primary.main",
              },
            },
          },
        }}
      >
        <ListItem key="logo" sx={{ marginTop: 2, marginBottom: 2 }}>
          <ListItemIcon>
            <Clear
              sx={{ marginTop: 1.5, marginBottom: 1.5, cursor: "pointer" }}
              onClick={toggleDrawer}
            />
          </ListItemIcon>
        </ListItem>
        <ListItem
          onClick={() => handleNavigateClick(routes.home)}
          button
          key="home"
        >
          <ListItemText
            sx={{ marginLeft: 0.5 }}
            primary="HOME"
            primaryTypographyProps={{ variant: "windsor" }}
          />
        </ListItem>
        <ListItem
          id="journal-listitem"
          onClick={() => handleNavigateClick(routes.journal)}
          key="journal"
          button
        >
          <ListItemText
            sx={{ marginLeft: 0.5 }}
            primary="JOURNAL"
            primaryTypographyProps={{ variant: "windsor" }}
          />
        </ListItem>
        <ListItem
          id="shop-listitem"
          onClick={() => handleNavigateClick(routes.shop)}
          key="shop"
          button
        >
          <ListItemText
            sx={{ marginLeft: 0.5 }}
            primary="SHOP"
            primaryTypographyProps={{ variant: "windsor" }}
          />
        </ListItem>
        <ListItem
          id="events-listitem"
          onClick={() => handleNavigateClick(routes.events)}
          key="events"
          button
        >
          <ListItemText
            sx={{ marginLeft: 0.5 }}
            primary="EVENTS"
            primaryTypographyProps={{ variant: "windsor" }}
          />
        </ListItem>
        <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
        {locations
          .filter(({ node }) => Boolean(node.title))
          .map(({ node }) => (
            <ListItem
              key={node.id}
              onClick={() =>
                handleNavigateClick(
                  routes.locationPage.replace(":slug", node.slug)
                )
              }
              button
              sx={{
                display: `flex`,
                flexDirection: `column`,
                alignItems: `flex-start`,
              }}
            >
              <ListItemText
                primary={node.title ? node.title.toUpperCase() : ""}
                primaryTypographyProps={{ variant: "windsor" }}
                // classes={{primary: classes.listItemText}}
                sx={{
                  marginLeft: 0.5,
                }}
              />
              <ListItemText
                primary={node.subtitle ? node.subtitle.toUpperCase() : ""}
                primaryTypographyProps={{ variant: "windsorRegular" }}
                // classes={{primary: classes.listItemText}}
                sx={{
                  marginLeft: 0.5,
                  marginTop: -0.5,
                }}
              />
            </ListItem>
          ))}
      </List>
    </Drawer>
  )
}

DrawerMenu.propTypes = {
  toggleDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}
