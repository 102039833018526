/* eslint-disable react/prop-types */
import React from "react";
import TopLayout from "./TopLayout";
// import { StyledEngineProvider } from '@mui/material/styles';

export const wrapRootElement = ({element, props}) => {
  return (
    <TopLayout>{element}</TopLayout>
  );
};
