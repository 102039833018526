import React from "react"
import { Container, Box, Typography, Button, Grid } from "@mui/material"
import { trackEvent } from "../../services/analytics";

export default function CookieBanner(props) {

  const { handleSetCookieStatusAllow } = props;

  const handleAllowCookies = () => {
    handleSetCookieStatusAllow("allow")
    trackEvent("cookies_accepted")
  }

  const handleDeclineCookies = () => {
    handleSetCookieStatusAllow("decline")
    trackEvent("cookies_declined")
  }
  
  return (
    <Container
      sx={{
        backgroundColor: "secondary.main",
        overflow: "hidden",
        position: "fixed",
        bottom: 0,
        zIndex: 'tooltip'
      }}
      disableGutters
      maxWidth="xl"
      className="cookieBannerContainer"
      id="cookiebannercontainer"
      //fixed
    >
      <Grid container sx={{ margin: "auto", display: "flex", justifyContent: "space-between", alignItems: "center", maxWidth: "1000px" }}>
        <Grid item xs={12} md={9}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ paddingBottom: 1 }}>Can we use cookies to make your life better?</Typography>
            <Typography>They taste great with our coffee. We even use them to improve our site.</Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ p: 3 }}>
            <Button 
              variant="contained" 
              sx={{ marginRight: 2}}
              onClick={handleAllowCookies}
            > 
              Allow
            </Button>
            <Button 
              variant="text" 
              sx={{color: "text.grey"}} 
              size="small"
              onClick={handleDeclineCookies}
            >
              Decline
            </Button>
          </Box>
        </Grid>
      </Grid>

    </Container>
  )
}

