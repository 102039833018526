// React
import React, { useRef, useState } from "react"
import { navigate, graphql, useStaticQuery } from "gatsby"

// Constants
import * as routes from "../../constants/routes"

// Components
import DrawerMenu from "./DrawerMenu"

// Scroll hook
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

// Material ui
import {
  IconButton,
  AppBar,
  Toolbar,
  Box,
  Hidden,
  Link,
  Typography,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import NavLogo from "./NavLogo"

const linkStyles = {
  paddingRight: 3,
  paddingLeft: 3,
  color: "black",
  fontSize: "1.2rem",
  marginTop: 1,
  textDecoration: "none",
  "& .MuiTypography-root:hover": {
    color: "primary.main",
    textDecoration: "none",
    cursor: "pointer",
  },
}

// Get locations to show in mobile hamburger menu
function useNavAssets() {
  return useStaticQuery(
    graphql`
      query {
        allContentfulLocation {
          edges {
            node {
              id
              title
              subtitle
              slug
            }
          }
        }
      }
    `
  )
}

function Navbar(props) {
  const { allContentfulLocation } = useNavAssets()

  const [drawerIsOpen, setDrawerIsOpen] = React.useState(false)

  const handleDrawerToggle = () => setDrawerIsOpen(!drawerIsOpen)

  const handleNavigateClick = (route) => {
    drawerIsOpen && setDrawerIsOpen(false)
    return navigate(route)
  }

  // Color change on scroll
  const [color, setColor] = useState("background.light")
  const navBarRef = useRef(null)

  useScrollPosition(({ prevPos, currPos }) => {
    const navBarHeight = navBarRef.current.getBoundingClientRect().height
    const wrappers = document.getElementsByClassName("backgroundWrapper") // HTML Collection
    // Create array with all BackgroundWrappers in viewport
    const wrappersVisible = Array.from(wrappers).filter((wrapper) => {
      return isElementInViewport(wrapper, navBarHeight)
    })
    // Get the color of the most top BackgroundWrapper in viewport and set it as navbar color
    if (wrappersVisible.length > 0) {
      setColor(window.getComputedStyle(wrappersVisible[0]).backgroundColor)
    }
  }, []) // Check why this is causing 2 renders?

  const isElementInViewport = (el, navBarHeight) => {
    var rect = el.getBoundingClientRect()
    // Still needs fix to compensate for navBarHeight so color changes slightly earlier

    return (
      rect.bottom > 0 &&
      rect.right > 0 &&
      rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect.top < (window.innerHeight || document.documentElement.clientHeight)
    )
  }

  return (
    <AppBar
      id="navbar"
      position="sticky"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: "none",
        backgroundColor: color,
        borderBottom: "1px solid black",
        borderColor: "borders",
        paddingBottom: 1,
        transition: "background-color 0.5s ease-in-out",
      }}
      ref={navBarRef}
    >
      <Toolbar
        sx={{
          justifyContent: {
            md: `center`,
            sm: `space-between`,
            xs: `space-between`,
          },
          alignItems: { md: `flex-start`, sm: `center` },
          display: { md: `flex`, sm: `grid`, xs: `grid` },
          gridTemplateColumns: { md: ``, sm: `20% 1fr 20%`, xs: `20% 1fr 20%` },
        }}
      >
        <Hidden mdUp>
          <NavLogo sm={true} />
          <Box sx={{ marginLeft: `auto` }}>
            <DrawerMenu
              isOpen={drawerIsOpen}
              toggleDrawer={handleDrawerToggle}
              locations={allContentfulLocation.edges}
              handleNavigateClick={handleNavigateClick}
            />
            <IconButton
              id="menu-btn"
              aria-label="Menu"
              onClick={handleDrawerToggle}
              style={{ marginLeft: 7 }}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Hidden>
        <Hidden mdDown>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              "& hr": {
                margin: 0.5,
              },
            }}
          >
            <Box
              sx={{
                marginTop: 2,
                display: `flex`,
                justifyContent: `flex-end`,
                alignItems: `flex-start`,
              }}
            >
              <Link
                onClick={() => handleNavigateClick(routes.locations)}
                sx={linkStyles}
              >
                <Typography
                  variant="windsor"
                  sx={{ margin: "auto", color: `titles.main` }}
                >
                  LOCATIONS
                </Typography>
              </Link>
              <Link
                onClick={() => handleNavigateClick(routes.events)}
                sx={linkStyles}
              >
                <Typography
                  variant="windsor"
                  sx={{ margin: "auto", color: `titles.main` }}
                >
                  EVENTS
                </Typography>
              </Link>
            </Box>

            <NavLogo />
            <Box
              sx={{
                marginTop: 2,
                display: `flex`,
                justifyContent: `flex-start`,
                alignItems: `flex-start`,
              }}
            >
              <Link
                onClick={() => handleNavigateClick(routes.journal)}
                sx={linkStyles}
              >
                <Typography
                  variant="windsor"
                  sx={{ margin: "auto", color: `titles.main` }}
                >
                  JOURNAL
                </Typography>
              </Link>
              <Link
                onClick={() => handleNavigateClick(routes.shop)}
                sx={linkStyles}
              >
                <Typography
                  variant="windsor"
                  sx={{ margin: "auto", color: `titles.main` }}
                >
                  SHOP
                </Typography>
              </Link>
            </Box>
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar

Navbar.propTypes = {}
