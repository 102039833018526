// Public routes
export const home = "/"
export const contact = "/contact/"
export const locations = "/locations/"
export const shop = "/shop/"
export const locationPage = "/locations/:slug/"
export const journal = "/journal/"
export const journalPost = "/journal/:slug/"
export const story = "/our-story"
export const events = "/events/"

export const privacy = "/privacy/"
export const termsOfService = "/terms-and-conditions/"
export const team = "/join-our-team"
export const cookies = "/cookie-policy"
export const youtube =
  "https://www.youtube.com/channel/UCw4_07BjcqWsXIfw-ZvSB8A"
export const linkedin = "https://www.linkedin.com/company/bouncespace"
export const instagram = "https://www.instagram.com/bouncespace/"
