// React/Gatsby
import React, { useState } from "react"

// Other libs
import * as yup from "yup"
import { Box, Button, Typography, InputBase } from "@mui/material"

// Arrow icon
import arrow from "../../assets/arrow-cropped.svg"

function isValidEmail(email) {
  return yup.string().email().required().isValid(email)
}

export default function EmailOnlyForm(props) {
  // TODO: Subscription function should be moved to ActiveCampaign API and put POST request in Gatsby Functions
  // Create subscribe.js in /pages/api/subscribe.js
  // Create .env variables and add them to Gatsby Cloud

  const [message, setMessage] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()

    const form = event.target
    const formData = new FormData(form)
    const validEmail = await isValidEmail(formData.get("email"))

    if (!validEmail) {
      setMessage("Please enter a valid email address.")
      return
    }

    try {
      const response = await fetch(
        "https://bouncespace3516.activehosted.com/proc.php",
        {
          method: "POST",
          mode: "no-cors",
          body: formData,
        }
      )

      if (response) {
        console.log("success")
        setMessage("Ahoy – welcome to the crew!")
      } else {
        console.log(response)
        console.log(response.statusText)
      }
    } catch (error) {
      // setMessage("Something went wrong. Please try again later.")
      setMessage("Ahoy – welcome to the crew!") // Temporary fix for CORS error
      // console.log(error)
    }
  }

  return (
    <Box
      sx={{
        width: `100%`,
        display: `flex`,
        justifyContent: { md: `right`, xs: `left` },
      }}
    >
      <Box
        component="form"
        // method="POST"
        // action="https://bouncespace3516.activehosted.com/proc.php"
        id="_form_4_"
        // noValidate
        onSubmit={handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "relative",
          left: `-8px`, // Theme spacing doesn't work with left? otherwise use 1
          width: 300,
        }}
      >
        <input type="hidden" name="u" value="4" />
        <input type="hidden" name="f" value="4" />
        <input type="hidden" name="s" />
        <input type="hidden" name="c" value="0" />
        <input type="hidden" name="m" value="0" />
        <input type="hidden" name="act" value="sub" />
        <input type="hidden" name="v" value="2" />
        <input
          type="hidden"
          name="or"
          value="8b4d838a2838d2c5c60eece6b9af6807"
        />
        <div>
          <Typography sx={{ marginLeft: 1 }}>STAY UPDATED</Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: 300,
              borderBottom: `1px solid black`,
              borderColor: "borders",
            }}
          >
            <InputBase
              component="input"
              type="text"
              id="email"
              name="email"
              placeholder="Type your email"
              required
              sx={{
                width: `100%`,
                border: "none",
                marginLeft: 1,
                paddingTop: 1,
                paddingBottom: 1,
                "& ::placeholder": {
                  opacity: 1,
                },
                "& :focus::placeholder": {
                  opacity: 0.5,
                },
              }}
            />
            <Button
              component="button"
              id="_form_4_submit"
              type="submit"
              sx={{
                padding: 0,
                fontSize: 20,
                color: "black",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <img
                src={arrow}
                style={{ width: `22px`, paddingBottom: `13px` }}
                alt="arrow"
              />
            </Button>
          </Box>
          {message.length > 0 && (
            <Typography sx={{ marginLeft: 1, marginTop: 1, width: 250 }}>
              {message}
            </Typography>
          )}
        </div>
      </Box>
    </Box>
  )
}
