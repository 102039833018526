import { red, amber } from "@mui/material/colors"

const theme = {
  components: {
    MuiCssBaseline: {},
  },
  palette: {
    primary: {
      main: "#e74310", // BounceSpace Fire
      light: "#daa14c", // BounceSpace Fire Light (light orange) -> Needs updating
    },
    secondary: {
      main: "#ebe7d5", // BounceSpace Fire
      contrastText: "#fff",
    },
    titles: {
      main: `#263847`, // BounceSpace Marine
      light: `#ffffff`, // White
    },
    text: {
      main: `#263847`, // BounceSpace Marine
      marine: `#263847`, // BounceSpace Marine
      grey: `#222121`, // BounceSpace Dark Grey
    },
    borders: "#263847", // BounceSpace Marine
    error: {
      main: red[500],
    },
    warning: {
      main: amber[600],
    },
    background: {
      // Default is also set on body tag of html
      default: "#EEEBDC", // BounceSpace Champagne
      light: "#f5f3e9", // BounceSpace Champagne Light
    },
  },
  typography: {
    fontFamily: '"Roboto Mono", sans-serif',
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    allVariants: {
      color: "#263847", // BounceSpace Marine
    },
    h1: {
      fontFamily: '"WindsorPro-Bold", sans-serif',
    },
    h2: {
      fontFamily: '"WindsorPro-Bold", sans-serif',
      lineHeight: 1.3,
    },
    h3: {
      fontFamily: '"WindsorPro-Bold", sans-serif',
      lineHeight: 1.3,
    },
    h4: {
      fontFamily: '"WindsorPro-Bold", sans-serif',
    },
    h5: {
      fontFamily: '"WindsorPro-Bold", sans-serif',
    },
    h6: {
      fontFamily: '"WindsorPro-Bold", sans-serif',
    },
    robotoBody: {
      fontFamily: '"Roboto", sans-serif',
    },
    body1: {
      fontSize: `0.92rem`,
    },
    body2: {
      fontSize: `0.92rem`,
    },
    caption: {
      fontSize: `0.92rem`,
    },
    windsor: {
      fontFamily: '"WindsorPro-Bold", sans-serif',
    },
    windsorRegular: {
      fontFamily: `"WindsorPro-Regular", sans-serif`,
    },
    mono: {
      fontFamily: '"Roboto Mono", sans-serif',
    },
    monoMedium: {
      fontFamily: '"Roboto Mono", sans-serif',
    },
    monoLink: {
      fontFamily: '"Roboto Mono", sans-serif',
      textDecoration: `underline`,
      color: "#222121",
      "&:hover": {
        color: `#e74310`,
      },
    },
    variantMapping: {
      monoLink: "p",
    },
  },
}

export default theme
