import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import theme from "../../src/constants/theme"
import Navbar from "../../src/components/Navbar"
import Footer from "../../src/components/Footer"
import CookieBanner from "../../src/components/CookieBanner"
import { StyledEngineProvider } from "@mui/material/styles"
import "../../src/assets/layout.css" // For importing the Windsor font

export default function TopLayout(props) {

  const [ cookieStatusAllow, setCookieStatusAllow ] = React.useState("notset")
  const [ cookiesLoaded, setCookiesLoaded ] = React.useState(false)

  React.useEffect(() => {
    var nameEQ = "cookie_status_allow" + "=";
    var ca = document.cookie.split(';');
    //var newValue = false;
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)===' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) {
          setCookieStatusAllow(c.substring(nameEQ.length,c.length))
          //return
          //newValue = c.substring(nameEQ.length,c.length);
        } 
        setCookiesLoaded(true)
    }
    //setCookieStatusAllow(newValue)
  }, []);

  const handleSetCookieStatusAllow = (allow) => {
    var expires = "";
    const numDays = 540;
    var date = new Date();
    date.setTime(date.getTime() + (numDays*24*60*60*1000));
    expires = "; expires=" + date.toUTCString();
    document.cookie = "cookie_status_allow" + "=" + allow + expires + "; path=/";
    setCookieStatusAllow(allow)
  }
  // console.log("cookieStatusAllow", cookieStatusAllow)

  return (
    <React.Fragment>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <meta name="google-site-verification" content="KpBPRD3kzQCZf8pz7uetl9YAQN4A1BYverO8IRQAwFQ" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css2?family=Inter&family=Roboto&display=swap" rel="stylesheet" />
      </Helmet>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={createTheme(theme)}>
          <CssBaseline />
          <Navbar />
          <main>
            {props.children}
            {cookieStatusAllow === "notset" && cookiesLoaded ? <CookieBanner handleSetCookieStatusAllow={handleSetCookieStatusAllow} /> : undefined}
          </main>
          <Footer />
        </ThemeProvider>
      </StyledEngineProvider>
    </React.Fragment>
  )
}

TopLayout.propTypes = {
  children: PropTypes.node,
}
